

























































import { Room } from "@/models/room.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Vuetable, VuetablePagination } from "vuetable-2";
import { namespace } from "vuex-class";

const roomsNamespace = namespace("Rooms");

@Component({
  components: { Vuetable, VuetablePagination },
})
export default class UsersView extends Vue {
  isLoading = false;
  fields = ["name", "order", "actions"];

  $refs!: Vue["$refs"] & {
    roomstable: Vuetable;
    pagination: VuetablePagination;
  };

  @roomsNamespace.Getter("all")
  rooms!: Room[];

  onPaginationData(paginationData) {
    this.$refs.pagination.setPaginationData(paginationData);
  }

  onChangePage(page) {
    this.$refs.roomstable.changePage(page);
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.$store.dispatch("Rooms/fetchAll");
    this.isLoading = false;
  }

  async copyRoom(room) {
    const { id, ...rest } = room;
    rest.name = `${room.name} (copy)`;
    const newRoom = await this.$store.dispatch("Rooms/create", rest);

    this.$router.push({ name: "room-detail", params: { id: newRoom.id } });
  }

  async orderUp(room) {
    window.alert(room);
  }

  async orderDown(room) {
    window.alert(room);
  }

  editRoom(room) {
    this.$router.push({ name: "room-detail", params: { id: room.id } });
  }

  addRoom() {
    this.$router.push({ name: "room-create" });
  }

  async deleteRoom(room) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${room.name}?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.$store.dispatch("Rooms/delete", room.id);
      return this.doFetchAll();
    }
    this.isLoading = false;
  }

  async created() {
    this.doFetchAll();
  }
}
